import styled from 'styled-components'

export const ContactContainer = styled.div`
  background: #becbc0;
  
`

export const ContactWrapper = styled.div`
  display: grid;
  grid-template-rows: 0.5fr 1.5fr;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: 
          "header header"
          "left right";
  grid-gap: 10px;
  height: fit-content;
  width: 100%;
  max-width: 1400px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 50px;
  padding-bottom: 50px;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: 
          "header"
          "left" 
          "right";
    
    text-align: center;
  }
  
  
`

export const ContactHeader = styled.div`
  grid-area: header;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ContactContent = styled.div`

`

export const ContactContentLeft = styled.div`
  grid-area: left;
  display: flex;
  justify-content: center;
  padding-left: 50px;

  @media screen and (max-width: 768px) {
    padding-left: 0px;
  }
`

export const ContactContentRight = styled.div`
  grid-area: right;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ContactInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr);
  grid-template-areas: 
          "openingHours"
          "callingHours";
  grid-gap: 30px;

  @media screen and (max-width: 768px) {
    padding-top: 50px;
  }
`

export const ContactOpeningHours = styled.div`
  grid-area: openingHours;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const ContactCallingHours = styled.div`
  grid-area: callingHours;
  display: flex;
  flex-direction: column;
  justify-content: center;
`


export const ContactPossiblities = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 1fr);
  grid-template-areas: 
          "telefon"
          "destination"
          "email";

  @media screen and (max-width: 768px) {
    padding-top: 50px;
  }
`

export const ContactIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const IconWrap = styled.div`
  width: 3rem;
`

export const TextWrap = styled.div`
  margin: 0px;
`
