import styled from "styled-components";

export const MapContainer = styled.div`
  background: #79939E;
  
`

export const MapWrapper = styled.div`
  height: fit-content;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-top: 50px;
  padding-bottom: 50px;
  justify-content: center;
`

export const ContactIconWrapper = styled.div`
  position: relative;
  top: 50px;
  right: 50px;
  height: 50px;
  width: 50px;
  border-radius: 100%;
`
