import styled from 'styled-components'
import {Link as LinkS} from 'react-scroll'
import {FaTimes} from 'react-icons/fa'
import {ReactComponent as PhysiofitLogo} from "../../images/logo_physiofit.svg";

export const SideBarContainer = styled.aside`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: #E0D6CD;
  transition: 0.3s ease-in-out;
  opacity: ${({isOpen}) => (isOpen ? '100%' : '0%') };
  top:${({isOpen}) => (isOpen ? '0' : '-100%')};
`

export const CloseIcon = styled(FaTimes)`
  color: #fff;
`

export const Icon = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`

export const SideBarWrapper = styled.div`
  color: black;
`

export const SideBarLink = styled(LinkS)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  text-decoration: none;
  list-style: none;
  transition: 0.2s ease-in-out;
  color: #fff;
  cursor: pointer;
  
  &:hover {
    color: blue;
    transition: 0.2s ease-in-out;
  }
`

export const SideBarMenu = styled.ul`
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-rows: repeat(4,80px);
  text-align: center;
  padding: 0;
  
  @media screen and (max-width: 480px) {
    grid-template-rows: repeat(6, 60px);
  }
`

export const SideBarLogo = styled(PhysiofitLogo)`
  display: flex;
  justify-content: center;
  width: 400px;
  cursor: pointer;
  fill: ${(props) => props.fill};

  @media screen and (max-width: 1000px) {
    width: 350px;
  }


  @media screen and (max-width: 600px) {
    width: 300px;
  }
  
  
  @media screen and (max-width: 400px) {
    width: 200px;
  }
  
`

export const SideBarLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5rem 0;
`

