import React from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/index";
import Impressum from "./pages/impressum";
import Corona from "./pages/corona";
import Job from "./pages/job";

function App() {
  return (
      <Router>
        <Routes>
            <Route path={"/impressum"} element={<Impressum/>} exact />
            <Route path={"/karriere"} element={<Job/>} exact />
            <Route path={"/corona"} element={<Corona/>} exact />
            <Route path={"/"} element={<Home/>} exact />
        </Routes>
      </Router>
  );
}

export default App;
