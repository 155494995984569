import React from "react";

import {
    Column1,
    Column2, DescWrapper, HeadingWrapper,
    Img,
    ImgWrap,
    InfoContainer,
    InfoRow,
    InfoWrapper, Subtitle,
    TextWrapper,
    TopLine
} from "./InfoElement";
import {Heading, Text} from "../Font/FontElements";

const InfoSection = ({backgroundColor, textColor, id, imgStart, topLine, headline, description, img, alt}) => {
    return (
        <>
            <InfoContainer color={backgroundColor} id={id}>
                <InfoWrapper>
                    <InfoRow imgStart={imgStart}>
                        <Column1>
                            <TextWrapper>
                                <TopLine>{topLine}</TopLine>
                                <HeadingWrapper>
                                    <Heading color={textColor}>{headline}</Heading>
                                </HeadingWrapper>
                                <DescWrapper>
                                    <Text color={textColor}>{description}</Text>
                                </DescWrapper>
                            </TextWrapper>
                        </Column1>
                        <Column2>
                            <ImgWrap>
                                <Img src={img} alt={alt}/>
                            </ImgWrap>
                        </Column2>
                    </InfoRow>
                </InfoWrapper>
            </InfoContainer>
        </>
    )
}

export default InfoSection