import React, {useState} from "react";
import {
    ContentContainer,
    ContentWrapper, Logo, LogoLink, LogoNav, Paragraph,
    ReturnButton,
    SiteContent,
    SiteWrapper
} from "../components/ExtraSite/ExtraSiteElements";

const Impressum = () => {
    return (
        <>
            <LogoNav color={"#BECBC0"}>
                <LogoLink to={'/'}>
                <Logo fill={'#768F85'} />
                </LogoLink>
            </LogoNav>
            <SiteContent color={"#BECBC0"}>
                <SiteWrapper height={"100%"}>
                    <ContentWrapper>
                        <ContentContainer color={"#768F85"}>
                            <Paragraph>
                                <h3>Angaben gemäß § 5 TMG</h3>
                                <p>Patricia Vajcek</p>
                                <p>Physiofit Vajcek</p>
                                <p>Hanauer Landstraße 9</p>
                                <p>63526 Erlensee</p>
                            </Paragraph>

                            <Paragraph>
                                <h3>Kontakt</h3>
                                <p>Tel.: +49 (0)6183 1082</p>
                                <p>Email: info@physiofit-erlensee.de</p>
                            </Paragraph>

                            <Paragraph>
                                <h3>Berufsbezeichnung</h3>
                                <p>Physiotherapeutin (B.Sc.)</p>
                                <p>Zuständige Kammer: Regierungspräsidium Darmstadt</p>
                                <p>Verliehen durch: Hessen, Deutschland</p>
                                <p>Es gilt die berufsrechtliche Regelung des Physiotherapeutengesetzes </p>
                                <p>Einsehbar unter: abc</p>
                            </Paragraph>

                            <Paragraph>
                                <h3>Aufsichtsbehörde</h3>
                                <p>Regierungspräsidium Darmstadt</p>
                                <p>Presse- und Öffentlichkeitsarbeit</p>
                                <p>Luisenplatz 2</p>
                                <p>64283 Darmstadt</p>
                                <p>https://rp-darmstadt.hessen.de/</p>
                            </Paragraph>

                            <Paragraph>
                                <h3>Angaben zur Berufshaftpflichtversicherung</h3>
                                <p>Es besteht eine Berufshaftpflichtversicherung bei:</p>
                                <p>R+V Allgemeine Versicherung AG</p>
                                <p>Voltastraße 84</p>
                                <p>60486, Frankfurt</p>
                                <p>Geltungsbereich: Deutschland</p>
                            </Paragraph>

                            <Paragraph>
                                <h3>Streitschlichtung</h3>
                                <p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS)
                                    bereit: <a
                                        href={"https://ec.europa.eu/consumers/odr"}>https://ec.europa.eu/consumers/odr </a>.

                                    Unsere E-Mail-Adresse finden Sie oben im Impressum. Wir sind nicht bereit oder
                                    verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
                                    teilzunehmen.</p>
                            </Paragraph>

                            <Paragraph>
                                <h3>Haftung für Inhalte</h3>
                                <p>Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen
                                    Seiten
                                    nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
                                    Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde
                                    Informationen zu überwachen oder nach Umständen zu forschen, die auf eine
                                    rechtswidrige
                                    Tätigkeit hinweisen.

                                    Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den
                                    allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist
                                    jedoch
                                    erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
                                    Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte
                                    umgehend
                                    entfernen.</p>
                            </Paragraph>

                            <Paragraph>
                                <h3>Haftung für Links</h3>
                                <p>Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir
                                    keinen
                                    Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr
                                    übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter
                                    oder
                                    Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
                                    Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum
                                    Zeitpunkt der Verlinkung nicht erkennbar.

                                    Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
                                    Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
                                    Rechtsverletzungen werden wir derartige Links umgehend entfernen.</p>
                            </Paragraph>

                            <Paragraph>
                                <h3>Urheberrecht</h3>
                                <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten
                                    unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung,
                                    Verbreitung
                                    und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
                                    schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien
                                    dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.

                                    Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die
                                    Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
                                    gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam
                                    werden,
                                    bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
                                    werden wir derartige Inhalte umgehend entfernen.</p>
                            </Paragraph>

                            <Paragraph>
                                <h3>Informationen zum Datenschutz nach EU-DSGVO</h3>
                                Unser Unternehmen prüft regelmäßig bei Vertragsabschlüssen und in bestimmten Fällen, in denen ein
                                berechtigtes Interesse vorliegt, auch bei Bestandskunden Ihre Bonität. Dazu arbeiten wir mit der
                                Creditreform Boniversum GmbH, Hammfelddamm 13, 41460 Neuss, zusammen, von der wir die dazu
                                benötigten Daten erhalten. Zu diesem Zweck übermitteln wir Ihren Namen und Ihre Kontaktdaten an
                                die Creditreform Boniversum GmbH. Die Informationen gem. Art. 14 der EU DatenschutzGrundverordnung zu der bei der Creditreform Boniversum GmbH stattfindenden Datenverarbeitung
                                finden Sie hier: www.boniversum.de/eu-dsgvo/

                            </Paragraph>

                        </ContentContainer>
                        <ReturnButton to={"/"} color={"#E5EAE6"} bc={"#768F85"}>zurück</ReturnButton>
                    </ContentWrapper>
                </SiteWrapper>
            </SiteContent>
        </>
    )
}

export default Impressum