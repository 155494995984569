import styled from "styled-components";
import {ReactComponent as Placeholder} from "../../images/placeholder.svg";


export const TeamContainer = styled.div`
  background: #E0D6CD;
  
`

export const TeamWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  max-width: 1500px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 50px;
  padding-bottom: 50px;
  justify-content: center;
`

export const TeamHeader = styled.div`
  grid-area: header;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
`

export const TeamContent = styled.div`
  grid-area: content;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
`

export const TeamMemberContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 600px;
  width: 350px;

  @media screen and (max-width: 768px) {
    flex: 0 0 auto;
  }
  
`

export const TeamMemberWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.9fr 0.6fr;

  grid-template-areas: 
          "header"
          "content";
  grid-gap: 10px;
`

export const TeamMemberImgWrapper = styled.div`
  grid-area: header;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const TeamMemberTextWrapper = styled.div`
  grid-area: content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const TeamMemberImg = styled(Placeholder)`
  border-radius: 40%;
  width: 70%;
  fill: #768F85;
`

