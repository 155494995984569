import styled from "styled-components";
import {Link as LinkR} from 'react-router-dom'
import {ReactComponent as PhysiofitLogo} from "../../images/logo_physiofit.svg";

export const SiteContent = styled.div`
  background-color: ${(props) => props.color}};
  height: ${(props) => props.height}};
`

export const SiteWrapper = styled.div`
  z-index: 1;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  //padding-top: 150px;
  justify-content: center;
  height: ${(props) => props.height}};
`

export const NavigationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 100px;
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  padding-left: 50px;
  padding-right: 50px;
  color: ${(props) => props.color}};

  @media screen and (max-width: 768px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`

export const ReturnButton = styled(LinkR)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 40px;
  border: none;
  padding: 5px;
  text-align: center;
  text-decoration: none;
  border-radius: 30px;
  cursor: pointer;
  margin-top: 30px;
  opacity: 0.5;
  color: ${(props) => props.color}};
  background-color: ${(props) => props.bc}};

  &:hover {
    opacity: 1;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ColorWrapper = styled.div`
color: red;
`

export const ImpressumContainer = styled.div`

`

export const ImpressumWrapper = styled.div`

`

export const ImpressumContent = styled.div`

`

export const Paragraph = styled.div`
  font-size: 20px;
  @media screen and (max-width: 768px) {
    font-size: 15px;
    text-align: center;
    padding: 0 0;
  }
`

export const LogoNav = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.color}};
`

export const Logo = styled(PhysiofitLogo)`
  display: flex;
  justify-content: center;
  width: 400px;
  cursor: pointer;
  fill: ${(props) => props.fill};

  @media screen and (max-width: 1920px) {
    width: 300px;
  }

  @media screen and (max-width: 1400px) {
    width: 200px;
  }

  @media screen and (max-width: 900px) {
    justify-content: left;
  }
`

export const LogoLink = styled(LinkR)`
  
`

export const Container = styled.div`
    height: 100%;
`

export const UL = styled.ul`
  
  @media screen and (max-width: 768px) {
    list-style: none;
  }
`