import React from "react";
import Video from '../../videos/video.mp4'
import {HeroBg, HeroContainer, HeroContent, HeroH1, HeroP, ImageBg, VideoBg} from "./HeroElements";
import {Heading, Text} from "../Font/FontElements";
import Image from '../../images/start.jpg'


const HeroSection = () => {
    return (
        <HeroContainer>
            <HeroBg>
                <ImageBg src={Image}/>
                {/*<VideoBg autoPlay loop muted src={Video} />*/}
            </HeroBg>
            <HeroContent>
                <Heading color={'#E0D6CD'}>Herzlich Willkommen bei Physiofit Vajcek im Herzen
                    von Erlensee!</Heading>
            </HeroContent>
        </HeroContainer>
    )
}

export default HeroSection;