import styled from "styled-components";
import {Link} from 'react-router-dom'

export const FooterContainer = styled.footer`
  background: #E0D6CD;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const FooterWrapper = styled.div`
  display: flex;
  height: 200px;
  width: 200px;
  padding: 10px 10px 10px 10px;
`

export const FooterLogo = styled.img`
  width: 20%;
  margin: 0 0 10px 0;
  padding-right: 0;
`

export const FooterLink = styled.a`

`