import React, {useState} from "react";
import NavBar from "../components/NavBar";
import {
    ColorWrapper,
    ContentContainer, ContentWrapper, Logo, LogoLink, LogoNav,
    NavigationContainer,
    ReturnButton,
    SiteContent, SiteWrapper
} from "../components/ExtraSite/ExtraSiteElements";
import {Subtitle, Text} from "../components/Font/FontElements";
import Footer from "../components/Footer";
import SideBar from "../components/SideBar";

const Corona = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }


    return (
        <>
            <LogoNav color={"#79939E"}>
                <LogoLink to={'/'}>
                    <Logo fill={'#F3EEEB'} />
                </LogoLink>
            </LogoNav>
            <SiteContent color={"#79939E"}>
                <SiteWrapper height={"1200px"}>
                    <ContentWrapper>
                        <ContentContainer color={"#F3EEEB"}>
                            <Text>Liebe Patienten,</Text>
                            <Text>
                                wir möchten gerne zu Ihrem Schutz beitragen, jedoch ist dies nur durch Ihre Mitarbeit
                                gewährleistet. Deshalb bitten wir Sie, bei Erkältungs-symptomen wie Fieber, Husten,
                                Atemproblemen und Schnupfen, den Termin telefonisch 24 Stunden vorher abzusagen oder zu
                                verschieben. Wenn Sie den Termin regulär wahrnehmen, bitten wir Sie um folgendes zu
                                beachten:
                            </Text>
                            <ul>
                                <li>Kommen Sie bitte pünktlich, aber nicht überpünktlich zum vereinbarten Termin. So
                                    können wir die Aufenthaltsdauer gering wie möglich halten
                                </li>
                                <li>Betreten Sie die Praxis bitte nur mit FFP-2-Maske (ohne
                                    Atemventil)
                                </li>
                                <li>Nach dem Betreten der Praxis desinfizieren Sie bitte Ihre Hände</li>
                                <li>Bitte bringen Sie zu jeder Behandlung Ihr eigenes Handtuch mit</li>
                            </ul>
                            <Text>
                                Wir bedanken uns recht herzlich für Ihre Unterstützung und Mithilfe in dieser Zeit!
                            </Text>

                        </ContentContainer>
                        <ReturnButton to={"/"} color={"#657E88"} bc={"#F3EEEB"}>zurück</ReturnButton>
                    </ContentWrapper>
                </SiteWrapper>
            </SiteContent>
        </>
    )
}

export default Corona