import styled from 'styled-components'
import {HiChevronUp} from 'react-icons/hi'


export const FloatingButtonContainer = styled.div`
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 999;
  cursor: pointer;
`

export const FloatingButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border-color: transparent;
  opacity: 0.5;
 // background: #fff;

  &:hover {
    opacity: 1;
  }
`

export const FloatingButtonIcon = styled(HiChevronUp)`
color: black;
  width: 20px;
  height: 20px;
`