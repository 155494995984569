import React from "react";
import {GoalContainer, GoalContent, GoalWrapper} from "./GoalElements";
import {Text} from "../Font/FontElements";

const Goal = () => {

    const text1 = 'Wir nehmen uns Zeit für Sie, um Ihren Körper wieder ins Gleichgewicht zu bringen. Wir sehen diesen als Einheit. Damit können wir die Beschwerden an ihrer Ursache greifen, um sie nachhaltig und dauerhaft zu behandeln.';
    const text2 = 'Unsere Ziele sind, Sie langfristig bei einem schmerzfreien, gesunden und bewussten Leben zu unterstützen sowie Ihre körperliche Fitness zu stabilisieren und zu stärken, um so ein besseres Wohlbefinden zu schaffen und einem Ungleichgewicht vorzubeugen.'
    const text3 = 'Dafür sind wir bestens ausgebildet und können durch die Größe und Anzahl der Therapeut*innen ein breites Behandlungsspektrum in der Rehabilitation, Prävention und im Wellnessbereich anbieten. Alle unsere Therapeut*innen verfügen über spezielle Fortbildungen, die natürlich regelmäßig erneuert werden.'
    const text4 = 'Wir wünschen uns, dass Sie sich bei uns wohl fühlen und freuen uns Sie auf dem Weg der Genesung zu unterstützen.';


    return (
        <>
            <GoalContainer color={'#768F85'}>
                <GoalWrapper>
                    <GoalContent>
                        <Text color={'#F3EEEB'}>{text1}</Text>
                        <Text color={'#F3EEEB'}>{text2}</Text>
                        <Text color={'#F3EEEB'}>{text3}</Text>
                        <Text color={'#F3EEEB'}>{text4}</Text>
                    </GoalContent>
                </GoalWrapper>
            </GoalContainer>

        </>
    )

}

export default Goal