import styled from "styled-components";
import {Link as LinkR} from 'react-router-dom'

export const FooterImpressumContainer = styled.div`
  background: #E0D6CD;

`
export const FooterImpressumWrapper = styled.div`
  height: fit-content;
  display: flex;
  justify-content: flex-end;
  padding-right: 50px;
`

export const FooterImpressumLink = styled(LinkR)`
  text-decoration: none;
`