import React from "react";
import {FooterContainer, FooterWrapper} from "./FooterElements";
import {ReactComponent as Logo} from '../../images/Logo_rund_blau.svg'

const Footer = () => {
    return (
        <FooterContainer>
            <FooterWrapper>
                <Logo />
            </FooterWrapper>
        </FooterContainer>
    )
}

export default Footer