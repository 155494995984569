import React from "react";
import {
    Container,
    ContentContainer,
    ContentWrapper, Logo, LogoLink, LogoNav, Paragraph,
    ReturnButton,
    SiteContent,
    SiteWrapper, UL
} from "../components/ExtraSite/ExtraSiteElements";

const Job = () => {
    return (
        <Container>
            <LogoNav color={"#BECBC0"}>
                <LogoLink to={'/'}>
                    <Logo fill={'#768F85'} />
                </LogoLink>
            </LogoNav>
            <SiteContent color={"#BECBC0"} height={'100vh'}>
                <SiteWrapper height={"100%"}>
                    <ContentWrapper>
                        <ContentContainer color={"#768F85"}>
                            <Paragraph>
                                <h2>Physiotherapeut*in</h2>
                                <p>Ein junges und dynamisches Physio-Team möchte sich im Herzen von Erlensee mit
                                    Dir verstärken! Unsere Physiotherapie Praxis verfügt über große, moderne und helle
                                    Einzelbehandlungsräume. Die Praxis ist orthopädisch/chirurgisch ausgerichtet. Wir
                                    legen großen Wert auf eine persönliche, individuelle und qualitativ hochwertige
                                    Therapie unserer Patienten/innen. Wir bieten ein positives Arbeitsklima, eine
                                    leistungsgerechte Bezahlung sowie Unterstützung bei Fortbildungen. Wenn Sie
                                    engagiert, motiviert und in einer modernen Ausstattung in der Physiotherapie tätig
                                    sein möchten, vereinbaren Sie jetzt mit uns einen Bewerbungstermin oder schicken
                                    Ihre Bewerbungsunterlagen per Post oder E-Mail an unsere genannte Adresse. </p>
                                <h2>Deine Vorteile</h2>
                                <UL>
                                    <li>30 Tage Urlaub mit flexibler Urlaubsplanung</li>
                                    <li>zusätzliche 10 Tage Fortbildungsurlaub</li>
                                    <li>Fortbildungskosten werden übernommen inkl. Übernachtung und Versorgung</li>
                                    <li>regelmäßige Teambesprechungen</li>
                                    <li>die Terminierung von Kunden wird für dich übernommen</li>
                                    <li>Betriebsausflüge und regelmäßige Teamessen</li>
                                    <li>Gratifikationen zu Weihnachten, Ostern und zum Geburtstag</li>
                                    <li>überdurchschnittliches Gehalt ab 38.000€/Jahr bei Einstieg mit Fortbildungen
                                        wie zum Beispiel MT, MLD und KG-ZNS</li>
                                    <li>Fahrtkostenzuschuss zur Praxis</li>
                                </UL>
                                <h3>Weitere Vorteile</h3>
                                <UL>
                                    <li>Du bist durch eine Fortbildung an deinen jetzigen Arbeitgeber gebunden? Wir
                                        übernehmen für dich die Auszahlung deiner Fortbildungskosten.</li>
                                    <li>Du möchtest in den Umkreis von Erlensee ziehen? Wir übernehmen die Kosten
                                        für den Umzug.</li>
                                    <li>Attraktive Mitarbeiterangebote wie JobRad stehen zur Verfügung</li>
                                </UL>
                            </Paragraph>

                        </ContentContainer>
                        <ReturnButton to={"/"} color={"#E5EAE6"} bc={"#768F85"}>zurück</ReturnButton>
                    </ContentWrapper>
                </SiteWrapper>
            </SiteContent>
        </Container>
    )
}

export default Job