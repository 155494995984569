import React from "react";
import {FooterImpressumContainer, FooterImpressumLink, FooterImpressumWrapper} from "./FooterImpressumElements";
import {Text} from "../Font/FontElements";

const FooterImpressum = () => {
    return (
        <>
            <FooterImpressumContainer>
                <FooterImpressumWrapper>
                    <FooterImpressumLink to="/impressum">
                        <Text color={"#657E88"}>Impressum</Text>
                    </FooterImpressumLink>
                </FooterImpressumWrapper>
            </FooterImpressumContainer>
        </>
    )
}

export default FooterImpressum