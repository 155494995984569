import styled from "styled-components";

export const WellnessContainer = styled.div`
  background: ${(props) => props.color}};

`

export const WellnessWrapper = styled.div`
  display: flex;
  z-index: 1;
  height: fit-content;
  width: 100%;
  max-width: 1100px;
  max-height: 840px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 50px;
  padding-bottom: 50px;
  justify-content: center;
  align-items: center;
`

export const HeadingWrapper = styled.div`
  grid-area: header;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`

export const WellnessGrid = styled.div`
  display: grid;
  grid-template-rows: 0.2fr 1fr;
  grid-template-columns: repeat(2, 1fr);
  grid-template:
          "header header"
          "text img";
  grid-column-gap: 200px;

  @media screen and (max-width: 1100px) {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns:  1fr;
    grid-template:
          "header"
          "text";
  }
`

export const ImageCell = styled.div`
  grid-area: img;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1100px) {
    display: none;
  }
`

export const ListCell = styled.div`
  grid-area: text;
  display: flex;
  justify-content: center;
  justify-items: center;
`

export const ImageWrapper = styled.img`
  width: 100%;
`

export const ListWrapper = styled.ul`
  @media screen and (max-width: 768px) {
    padding-inline: 0px;
  }
`