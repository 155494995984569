import styled from 'styled-components'
import {Link as LinkR} from 'react-router-dom'
import {Link as LinkS} from 'react-scroll'
import {ReactComponent as PhysiofitLogo} from "../../images/logo_physiofit.svg";

export const Nav = styled.nav`
  background: ${({scrollNav}) => (scrollNav ? '#B3C0B3' : 'transparent')};
  height: 160px;
  margin-top: -160px;
  display: flex;
  justify-content: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;

  @media Screen and (max-width: 900px) {
    transition: 0.8s all ease;
    height: 80px;
    margin-top: -80px;
    
  }
`

export const NavBarContainer = styled.div`
  display: flex;
  height: 160px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 100%;
  margin-left: 50px;
  margin-right: 50px;
  justify-content: space-between;

  @media Screen and (max-width: 900px) {
    height: 80px;
    margin-left: 0;

  }
`

export const NavDir = styled(LinkR)`
  color: #fff;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-left: 24px;
  font-weight: bold;
  text-decoration: none;
`

export const NavLogo = styled(PhysiofitLogo)`
  display: flex;
  justify-content: center;
  width: 400px;
  cursor: pointer;
  fill: ${(props) => props.fill};

  @media screen and (max-width: 1920px) {
    width: 300px;
  }

  @media screen and (max-width: 1400px) {
    width: 200px;
  }

  @media screen and (max-width: 900px) {
    justify-content: left;
  }
`


export const MobilIcon = styled.div`
  display: none;

  @media screen and (max-width: 900px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: white;
  }
`

export const NavMenuLeft = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-right: 40px;
  padding-inline-start: 0;
  font-family: Recoleta-Medium, serif;

  @media screen and (max-width: 900px) {
    display: none;
  }
`

export const NavMenuRight = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  padding-inline-end: 40px;
  font-family: Recoleta-Medium, serif;

  @media screen and (max-width: 900px) {
    display: none;
  }
`

export const NavItem = styled.li`
  height: 80px;
  justify-self: center;
`

export const NavLinks = styled(LinkS)`
  color: #fff;
  display: flex;
  justify-self: right;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;

  &.active {
    border-bottom: 3px solid #fff;
  }
`

export const NavLinksJump = styled(LinkR)`
  color: #fff;
  display: flex;
  justify-self: right;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;

  &.active {
    border-bottom: 3px solid #fff;
  }
`



