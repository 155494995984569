import React from "react";
import {
    ImageCell,
    ImageWrapper, ListCell,
    ListWrapper,
    ServicesContainer,
    ServicesGrid,
    ServicesWrapper,
    HeadingWrapper,
} from "./ServicesElements";
import img from '../../images/massage1.jpg'
import {Heading, Text} from "../Font/FontElements";
import {ReactComponent as Icon} from "../../Icons/wirbel.svg";

const Services = () => {
    return (
        <>
            <ServicesContainer color={'#E0D6CD'} id={'leistungen'}>
                <ServicesWrapper>
                    <ServicesGrid>
                        <HeadingWrapper>
                            <Heading color={'#657E88'}>Physiotherapie</Heading>
                        </HeadingWrapper>
                        <ImageCell>
                            <Icon/>
                        </ImageCell>
                        <ListCell>
                            <ListWrapper>
                                <Text color={'#657E88'}>Krankengymnastik</Text>
                                <Text color={'#657E88'}>Manuelle Lymphdrainage</Text>
                                <Text color={'#657E88'}>Klassische Massage</Text>
                                <Text color={'#657E88'}>Rotlicht - Heiße Rolle</Text>
                                <Text color={'#657E88'}>Kryotherapie</Text>
                                <Text color={'#657E88'}>Hausbesuche</Text>
                                <Text color={'#657E88'}>Geschenkgutscheine </Text>
                            </ListWrapper>
                        </ListCell>
                    </ServicesGrid>
                </ServicesWrapper>
            </ServicesContainer>
        </>
    )
}

export default Services