import React, {useEffect, useState} from "react";
import {MobilIcon, Nav, NavBarContainer, NavItem, NavLinks, NavLinksJump, NavLogo, NavMenuLeft, NavMenuRight} from "./NavBarElement";
import {animateScroll as scroll} from 'react-scroll';
import { HiOutlineMenuAlt4 } from 'react-icons/hi';
import {NavBarText, Subtitle, Text} from "../Font/FontElements";
import {FooterImpressumLink} from "../FooterImpressum/FooterImpressumElements";

const NavBar = ({toggle}) => {
    const [scrollNav, setScrollNav] = useState(false);

    const changeNav = () => {
        if (window.scrollY >= 80) {
            setScrollNav(true);
        } else {
            setScrollNav(false);
        }
    }

    const toggleHome = () => {
        scroll.scrollToTop();
    }

    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    }, [])

    return (
        <>
            <Nav scrollNav={scrollNav}>
                <NavBarContainer>
                    <NavMenuLeft>
                        <NavItem>
                            <NavLinks to={'aktuelles'} smooth={true} duration={500} spy={true} exact={'true'}
                                      offset={-80}><NavBarText>Aktuelles</NavBarText></NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to={'leistungen'} smooth={true} duration={500} spy={true} exact={'true'}
                                      offset={-80}><NavBarText>Leistungen</NavBarText></NavLinks>
                        </NavItem>
                    </NavMenuLeft>
                    <NavLogo fill={'#fff'} to={'/'} onClick={toggleHome}/>
                    <NavMenuRight>
                        <NavItem>
                            <NavLinksJump to="/karriere">
                                <NavBarText>Karriere</NavBarText>
                            </NavLinksJump>
                        </NavItem>
                        <NavItem>
                            <NavLinks to={'kontakt'} smooth={true} duration={500} spy={true} exact={'true'}
                                      offset={-80}><NavBarText>Kontakt</NavBarText></NavLinks>
                        </NavItem>
                    </NavMenuRight>
                    <MobilIcon onClick={toggle}>
                        <HiOutlineMenuAlt4 />
                    </MobilIcon>
                </NavBarContainer>
            </Nav>
        </>
    );
};

export default NavBar