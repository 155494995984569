import React from "react";

import {Heading, Text} from "../Font/FontElements";
import {
    HeadingWrapper,
    ImageCell,
    ListCell,
    WellnessContainer,
    WellnessGrid,
    WellnessWrapper,
    ListWrapper
} from "./WellnessElements";
import {ReactComponent as Icon} from "../../Icons/hand.svg";

const Wellness = () => {
    return (
        <>
            <WellnessContainer color={'#79939E'}>
                <WellnessWrapper>
                    <WellnessGrid>
                        <HeadingWrapper>
                            <Heading color={'#F3EEEB'}>Wellness Anwendungen</Heading>
                        </HeadingWrapper>
                        <ImageCell>
                            <Icon/>
                        </ImageCell>
                        <ListCell>
                            <ListWrapper>
                                <Text color={'#F3EEEB'}>Fußreflexzonenmassage</Text>
                                <Text color={'#F3EEEB'}>Gesichts- & Kopfschmerzmassage</Text>
                                <Text color={'#F3EEEB'}>Rückenmassage</Text>
                                <Text color={'#F3EEEB'}>Teilkörpermassage</Text>
                                <Text color={'#F3EEEB'}>Ganzkörpermassage</Text>
                            </ListWrapper>
                        </ListCell>
                    </WellnessGrid>
                </WellnessWrapper>
            </WellnessContainer>
        </>
    )
}

export default Wellness