import React from "react";
import {
    CloseIcon,
    Icon,
    SideBarContainer,
    SideBarLink,
    SideBarLogo, SideBarLogoWrapper,
    SideBarMenu,
    SideBarWrapper
} from "./SideBarElements";
import {SideBarText, Text} from "../Font/FontElements";
import {NavLogo} from "../NavBar/NavBarElement";


const SideBar = ({isOpen, toggle}) => {
    return (
        <SideBarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SideBarLogoWrapper>
                <SideBarLogo fill={'#fff'} to={'/'}/>
            </SideBarLogoWrapper>

            <SideBarWrapper>
                <SideBarMenu>
                    <SideBarLink to={"aktuelles"} onClick={toggle}><SideBarText color={"#768F85"}>Aktuelles</SideBarText></SideBarLink>
                    <SideBarLink to={"leistungen"} onClick={toggle}><SideBarText color={"#768F85"}>Leistungen</SideBarText></SideBarLink>
                    <SideBarLink to={"team"} onClick={toggle}><SideBarText color={"#768F85"}>Team</SideBarText></SideBarLink>
                    <SideBarLink to={"kontakt"} onClick={toggle}><SideBarText color={"#768F85"}>Kontakt</SideBarText></SideBarLink>
                </SideBarMenu>
            </SideBarWrapper>
        </SideBarContainer>
    )
}

export default SideBar