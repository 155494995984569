import React from "react";
import {ReactComponent as Telephone} from '../../Icons/Icon_Telefon.svg'
import {ReactComponent as Pin} from '../../Icons/Icon_Standort.svg'
import {ReactComponent as Mail} from '../../Icons/Icon_Mail.svg'



import {
    ContactCallingHours,
    ContactContainer,
    ContactContentLeft,
    ContactContentRight,
    ContactHeader, ContactInfo, ContactOpeningHours, ContactPossiblities,
    ContactWrapper
} from "./ContactElements";
import ContactIcon from "./ContactIcon";
import {Heading, Subtitle, Text} from "../Font/FontElements";

const Contact = () => {
    return (
        <>
            <ContactContainer id={'kontakt'}>
                <ContactWrapper>
                    <ContactHeader>
                        <Heading color={'#657E88'}>So erreichen <br/> Sie uns</Heading>
                    </ContactHeader>
                    <ContactContentLeft>
                        <ContactInfo>
                            <ContactOpeningHours>
                                <Subtitle color={'#657E88'}>Öffnungszeiten</Subtitle>
                                <Text color={'#657E88'}>Termine nach Vereinbarung</Text>
                            </ContactOpeningHours>
                            <ContactCallingHours>
                                <Subtitle color={'#657E88'}>Telefonsprechzeiten</Subtitle>
                                <Text color={'#657E88'}>Montag bis Freitag 8-13 Uhr</Text>
                            </ContactCallingHours>
                        </ContactInfo>
                    </ContactContentLeft>
                    <ContactContentRight>
                        <ContactPossiblities>
                            <ContactIcon icon={<Telephone fill={'#657E88'}/>} text={"06183 1082"} color={'#657E88'}/>
                            <ContactIcon icon={<Pin fill={'#657E88'}/>} text={"Hanauer Straße 9"} sub={"63526 Erlensee"} color={'#657E88'}/>
                            <ContactIcon icon={<Mail fill={'#657E88'}/>} text={"info@physiofit-erlensee.de"} color={'#657E88'}/>
                        </ContactPossiblities>
                    </ContactContentRight>
                </ContactWrapper>
            </ContactContainer>
        </>
    )
}

export default Contact