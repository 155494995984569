import styled from "styled-components";

export const GoalContainer = styled.div`
  background: ${(props) => props.color}};

`

export const GoalWrapper = styled.div`
  display: flex;
  z-index: 1;
  height: fit-content;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 50px;
  padding-bottom: 50px;
  justify-content: center;
  align-items: center;
`

export const GoalContent = styled.div`
  text-align: center;
  
`