import React from "react";
import {ContactIconContainer, IconWrap} from "./ContactElements";
import {Text} from "../Font/FontElements";

const ContactIcon = ({icon, text,sub, color}) => {
    let textItem = <Text color={color}>{text}</Text>;

    if (sub) {
        textItem = <Text color={color}>{text} <br/> {sub}</Text>
    }

    return (
        <>
            <ContactIconContainer>
                <IconWrap>
                    {icon}
                </IconWrap>
                {textItem}
            </ContactIconContainer>

        </>
    )
}

export default ContactIcon