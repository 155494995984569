import React, {useEffect, useState} from "react";
import NavBar from "../components/NavBar";
import HeroSection from "../components/HeroSection";
import InfoSection from "../components/InfoSection";
import {homeObjFour, homeObjOne, homeObjThree, homeObjTwo} from "../components/InfoSection/Data";
import Contact from "../components/Contact";
import SideBar from "../components/SideBar";
import FloatingNavigationButton from "../components/FloatingNavigationButton";
import Map from "../components/Map";
import Footer from "../components/Footer";
import Team from "../components/Team";
import Goal from "../components/Goal";
import Services from "../components/Services";
import Wellness from "../components/Wellness";
import FooterImpressum from "../components/FooterImpressum";

const Home = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <>
            <SideBar isOpen={isOpen} toggle={toggle}/>
            {/*<FloatingNavigationButton/>*/}
            <NavBar toggle={toggle}/>
            <HeroSection/>
            <InfoSection {...homeObjOne}/>
            <Goal/>
            <Services/>
            <Wellness/>
            {/*<Team/>*/}
            <Contact/>
            {/*<Map/>*/}
            <Footer/>
            <FooterImpressum/>
        </>
    )
}

export default Home