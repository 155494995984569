import styled from 'styled-components'

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 50px;
  line-height: 1.1;
  font-weight: 600;
  color: ${(props) => props.color};
  font-family: strawfordregular, serif;
  text-align: ${(props) => props.align};;

  @media screen and (max-width: 500px) {
    font-size: 35px;
    text-align: center;
  }
`

export const Subtitle = styled.h2`
  margin-bottom: 10px;
  font-size: 40px;
  line-height: 24px;
  color: ${(props) => props.color};
  //text-align: ${(props) => props.align};
  font-family: strawfordregular, serif;

  @media screen and (max-width: 900px) {
    font-size: 32px;
  }
`

export const Text = styled.p`
  color: ${(props) => props.color};
  font-size: 30px;
  line-height: 40px;
  font-weight: 400;
  margin-bottom: 16px;
  font-family: Recoleta-Medium, serif;
  text-align: ${(props) => props.align};;

  @media screen and (max-width: 768px) {
    font-size: 24px;
    text-align: center;
  }
`

export const NavBarText = styled.p`
  color: ${(props) => props.color};
  font-size: 50px;
  line-height: 40px;
  font-weight: 400;
  margin-bottom: 16px;
  font-family: Recoleta-Medium, serif;
  text-align: ${(props) => props.align};;

  @media screen and (max-width: 1920px) {
    font-size: 40px;
    text-align: center;
  }

  @media screen and (max-width: 1400px) {
    font-size: 30px;
    text-align: center;
  }
`

export const SideBarText = styled.p`
  color: ${(props) => props.color};
  font-size: 50px;
  line-height: 40px;
  font-weight: 400;
  margin-bottom: 30px;
  font-family: Recoleta-Medium, serif;
  text-align: ${(props) => props.align};


  @media screen and (max-width: 1000px) {
    font-size: 40px;
  }


  @media screen and (max-width: 450px) {
    font-size: 30px;
  }
`