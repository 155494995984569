import logo from '../../images/logo_bildmarke.svg';
import massage1 from '../../images/massage1.jpg';

export const homeObjOne = {
    id: 'aktuelles',
    backgroundColor: '#E0D6CD',
    textColor: '#768F85',
    lightText: true,
    lightTextDesc: true,
    topLine: '',
    headline: 'Liebe Patientinnen, \n' +
        'liebe Patienten!',
    description: 'Endlich ist es geschafft und wir freuen uns, Sie auf unserer neuen Internetseite und ab Januar 2022 in unseren neuen, hellen und barrierefreien Praxisräumen in der Hanauer Straße 9 in Erlensee begrüßen zu können. ',
    buttonLabel: 'Get started',
    imgStart: true,
    img: logo,
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: false
}

export const homeObjTwo = {
    id: 'aktuelles_2',
    backgroundColor: '#768F85',
    textColor: '#F3EEEB',
    headline: 'Unser Ziel',
    description: 'Wir nehmen uns Zeit für Sie, um Ihren Körper wieder ins Gleichgewicht zu bringen. Wir sehen diesen als Einheit. Damit können wir die Beschwerden an ihrer Ursache greifen, um sie nachhaltig und dauerhaft zu behandeln.\n' +
        '\n' +
        'Unsere Ziele sind, Sie langfristig bei einem schmerzfreien, gesunden und bewussten Leben zu unterstützen sowie Ihre körperliche Fitness zu stabilisieren und zu stärken, um so ein besseres Wohlbefinden zu schaffen und einem Ungleichgewicht vorzubeugen.\n' +
        '\n' +
        'Dafür sind wir bestens ausgebildet und können durch die Größe und Anzahl der Therapeut*innen ein breites Behandlungsspektrum in der Rehabilitation, Prävention und im Wellnessbereich anbieten. Alle unsere Therapeut*innen verfügen über spezielle Fortbildungen, die natürlich regelmäßig erneuert werden. \n' +
        '\n' +
        'Wir wünschen uns, dass Sie sich bei uns wohl fühlen und freuen uns Sie auf dem Weg der Genesung zu unterstützen.  \n',
    buttonLabel: 'Get started',
    imgStart: true,
    img: logo,
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: false
}

export const homeObjThree = {
    id: 'leistungen',
    color: "#E0D6CD",
    lightText: true,
    lightTextDesc: true,
    topLine: '',
    headline: 'Unsere Leistungen',
    description: 'Krankengymnastik \n' +
        '\n' +
        'Manuelle Lymphdrainage \n' +
        '\n' +
        'Klassische Massage \n' +
        '\n' +
        'Schröpftherapie \n' +
        '\n' +
        'Fango- Rotlicht- Heiße Rolle\n' +
        '\n' +
        'Kinesiotherapie-Taping\n' +
        '\n' +
        'Kryotherapie \n' +
        '\n' +
        'Craniosacral-Therapie\n' +
        ' \n' +
        'Wirbelsäulentherapie nach Dorn und Breuss \n' +
        '\n' +
        'Hausbesuche \n' +
        '\n' +
        'Geschenkgutscheine \n',
    buttonLabel: 'Get started',
    imgStart: true,
    img: logo,
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: false
}

export const homeObjFour = {
    id: 'wellness',
    color: "#79939E",
    lightText: true,
    lightTextDesc: true,
    topLine: '',
    headline: 'Wellness Anwendungen',
    description: '',
    buttonLabel: 'Get started',
    imgStart: true,
    img: logo,
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: false
}
